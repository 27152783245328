import { useEffect, useState } from 'react';
export function TopSection() {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setIsVideoLoaded(true), 500); // Delay for better performance
    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);
  return <div className="relative w-full aspect-video" data-sentry-component="TopSection" data-sentry-source-file="topSection.tsx">
      {!isVideoLoaded ? <img src="/images/banner-player/loader.webp" alt="Preview" className="w-full h-full object-cover" /> : <video src="/images/banner-player/banner-video-wide.mp4" autoPlay loop muted playsInline className="w-full h-full object-cover" />}
    </div>;
}