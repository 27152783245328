import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React, { useContext, useEffect, useState } from 'react';
import { formatCurrencySymbol } from 'utils/functions/wallet';
import OneLinePayments from 'assets/icons/banks/oneLine';
import GlobalContext from 'context/global';
import InputGroup from 'components/uiKit/inputs/inputGroup';
import { useRouter } from 'next/router';
import { isValidDepositAmount, parseAmountValue } from 'utils/functions/ui/format';
import style from '../style.module.scss';
const values = [40, 80, 200];
const currency = 'EUR';
export function BottomSection() {
  const [value, setValue] = useState<any>({
    price: '',
    type: 'btn'
  });
  const {
    appType
  } = useContext(GlobalContext);
  const [isPrice, setIsPrice] = useState<'insufficient' | 'valid' | 'invalid' | ''>('');
  const {
    push
  } = useRouter();
  useEffect(() => {
    if (value.price === '') {
      setIsPrice('');
    } else if (isValidDepositAmount(value.price)) {
      setIsPrice('valid');
    } else {
      setIsPrice('invalid');
    }
  }, [value.price]);
  const getStatus = () => {
    if (!value) {
      return '';
    }
    if (isPrice === 'invalid' || isPrice === 'insufficient') {
      return 'error';
    }
    return 'success';
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const price = parseAmountValue(event.target.value);
    setValue({
      price,
      type: 'input'
    });
  };
  return <Grid horizontalAlgin="center" gap="1rem" wrap="wrap" responsiveWidth={{
    sm: 100,
    md: '55%',
    lg: '63%'
  }} className={style['section-conteiner']} padding={['ps-3', 'pe-3']} data-sentry-element="Grid" data-sentry-component="BottomSection" data-sentry-source-file="bottomSection.tsx">
      <Grid horizontalAlgin="center" gap="1rem" data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
        <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
          <Typography translateGroup="title-banner-home" translateKey="title" color="#fff" weight={700} className={style.title} data-sentry-element="Typography" data-sentry-source-file="bottomSection.tsx" />
        </Grid>
        <Grid horizontalAlgin="center" className={style['subtitle-conteiner']} padding={['pb-2']} data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
          <Typography translateGroup="subtitle-banner-home" translateKey="subtitle" color="#fff" weight={400} className={style['subtitle-text']} data-sentry-element="Typography" data-sentry-source-file="bottomSection.tsx" />
        </Grid>
      </Grid>
      <Grid horizontalAlgin="center" verticalAlgin="center" wrap={appType === 'desktop' ? 'nowrap' : 'wrap'} gap="0.875rem" className={style['container-form']} data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
        <Grid gap="0.875rem" wrap="nowrap" horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
          {values.map(btnValue => <Button key={`banner-form-btn-${btnValue}`} id={`banner-form-btn-${btnValue}`} block color={value.price === parseAmountValue(btnValue).toString() ? 'white' : 'outline-white'} onClick={() => setValue({
          price: parseAmountValue(btnValue).toString(),
          type: 'btn'
        })} className={style['button-price']}>
              <Typography className={style['button-text']}>
                {formatCurrencySymbol(btnValue, currency)}
              </Typography>
            </Button>)}
        </Grid>
        <Grid responsiveWidth={{
        sm: 100
      }} data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
          <InputGroup id="value" name="value" value={value.price} inputType="number" dataInput="true" status={getStatus()} feedback={isPrice === 'invalid' && value ? <Typography translateGroup="limit-value-feedback" translateKey="rang-valid-1-10000" weight={600} /> : isPrice === 'insufficient' && <Typography translateGroup="limit-value-feedback" translateKey="insufficient-funds" weight={600} />} inputProps={{
          className: style['input-price'],
          placeholder: 'MUU SUMMA',
          inputMode: 'decimal'
        }} onChange={handleInputChange} data-sentry-element="InputGroup" data-sentry-source-file="bottomSection.tsx" />
        </Grid>
      </Grid>
      <Grid style={{
      position: 'relative'
    }} horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
        <Grid className={style['container-btn-submit']} data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
          <Button id="btn-save-play" color="yellow" style={{
          width: '100%',
          height: '60px'
        }} onClick={() => {
          if (isPrice && isPrice !== 'valid') {
            return;
          }
          push(`/cashier/deposit?depositAmount=${value.price}`);
        }} data-sentry-element="Button" data-sentry-source-file="bottomSection.tsx">
            <Typography translateGroup="banner-home" translateKey="btn-save-play" style={{
            fontSize: '20px',
            fontWeight: 900
          }} data-sentry-element="Typography" data-sentry-source-file="bottomSection.tsx" />
          </Button>
        </Grid>
        <Grid style={{
        height: '12vh'
      }} data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
          <Grid className={style['container-slide']} data-sentry-element="Grid" data-sentry-source-file="bottomSection.tsx">
            <OneLinePayments data-sentry-element="OneLinePayments" data-sentry-source-file="bottomSection.tsx" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
}