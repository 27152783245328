import { TopSection } from './section/topSection';
import { BottomSection } from './section/bottomSection';
export default function MobileBanner() {
  return <div id="mobile-banner-content" className="relative min-h-screen w-full flex flex-col" data-sentry-component="MobileBanner" data-sentry-source-file="index.tsx">
      <div>
        <TopSection data-sentry-element="TopSection" data-sentry-source-file="index.tsx" />
      </div>

      <div className="flex-1 z-10 mt-[-1rem]">
        <BottomSection data-sentry-element="BottomSection" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
}